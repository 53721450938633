<template>
  <div class="pa-4">
    <h2 class="primary--text">Champs personnalisés</h2>
    <v-row class="mt-4" v-if="loading" align-content="center" justify="center">
      <v-progress-circular color="primary" indeterminate/>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th>Source</th>
          <th>Champ</th>
          <th>Valeur</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="select in selects" :key="select.id">
          <td>{{ SOURCES[select.source] }}</td>
          <td>{{ all_fields[select.field] }}</td>
          <td>{{ select.value }}</td>
          <td>
            <v-btn @click="deleteField(select.id)" color="red" class="white--text">
              <v-icon>mdi-trash-can</v-icon>
              Supprimer
            </v-btn>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <v-form id="selectForm" style="max-width: 100%" class="d-flex align-center justify-space-between" ref="selectForm">
              <v-select class="mx-2" form="selectForm" @change="selectModel.field = null"
                        :rules="[value => value !== null || 'Ne doit pas être vide']" :items="source_select"
                        item-text="name" item-value="value" v-model="selectModel.source"></v-select>
              <v-select class="mx-2" form="selectForm" :rules="[value => value !== null || 'Ne doit pas être vide']"
                        :items="fields_select" item-text="name" item-value="value"
                        v-model="selectModel.field"></v-select>
              <v-text-field class="mx-2" form="selectForm" :rules="[value => value !== null && value !== '' || 'Ne doit pas être vide']"
                            v-model="selectModel.value"></v-text-field>

              <v-btn color="primary" class="mx-2 white--text" @click="addField">
                <v-icon>mdi-plus</v-icon>
                Ajouter
              </v-btn>
            </v-form>
          </td>
          <td></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>
</template>

<script>
import {SelectRepository} from "@/repository/select";
import {convertToSelect} from "@/util";

const SOURCES = {
  "bien_immo": "Bien immobilier",
  "beneficiaire": "Bénéficiaire",
  "financement": "Financement",
  "document": "Document",
  "dossier": "Dossier",
  "entreprise": "Entreprise",
}

const FIELDS = {
  "beneficiaire": {
    "titre": "Titre",
    "situation_matrimoniale": "Situation matrimonial",
  },
  "dossier": {
    "attachments_types": "Types de pièces jointes",
    "statut_pret": "Statut de prêt",
    "enqueteurs": "Enquêteurs",
    "controleurs_deal": "Contrôleurs DEAL",
  },
  "bien_immo": {
    "type_bien": "Type de bien",
    "annee_construction": "Année construction",
    "nbre_pieces": "Nombre Pièces",
    "nbre_etage": "Nombre étage",
    "type_assainissement": "Type Assainissement",
  },
  "financement": {
    "origine_financement": "Origine de financement",
    "origine_emploi": "Origine de l'emploi",
    "origine_plafond": "Origine du plafond",
  },
  "document": {
    "signe_par": "Signé par",
  },
  "entreprise": {
    "entreprise_attachment": "Document de l'entreprise",
  }
}
const emptySelect = {
  source: "",
  field: "",
  value: "",
}
export default {
  name: "FieldSelect",
  async mounted() {
    this.loading = true;
    this.selects = await this.repository.fetch();
    this.selects.sort(function (a, b) {
        if(a.source.localeCompare(b.source) === 0) {
          a.field.localeCompare(b.field);
        } else {
          a.source.localeCompare(b.source);
        }
      });
    this.loading = false;
  },
  data() {
    return {
      selects: [],
      loading: false,
      repository: new SelectRepository(),
      SOURCES,
      FIELDS,
      selectModel: emptySelect,
    }
  },

  methods: {
    async fetchFields() {
      this.loading = true;
      this.selects = await this.repository.fetch();
      this.selects.sort(function (a, b) {
        if(a.source.localeCompare(b.source) === 0) {
          a.field.localeCompare(b.field);
        } else {
          a.source.localeCompare(b.source);
        }
      });
      this.loading = false;
    },
    async addField() {
      if (this.$refs.selectForm.validate()) {
        await this.repository.post(this.selectModel);
        await this.fetchFields();
      }
    },
    async deleteField(id) {
      await this.repository.delete({id});
      await this.fetchFields();
    },
  },
  computed: {
    source_select() {
      return convertToSelect(SOURCES);
    },
    fields_select() {
      return this.selectModel.source !== null ? convertToSelect(FIELDS[this.selectModel.source]) : [];
    },
    all_fields() {
      return {...FIELDS["beneficiaire"], ...FIELDS["bien_immo"], ...FIELDS["financement"], ...FIELDS["document"],
      ...FIELDS["dossier"], ...FIELDS["entreprise"]};
    },
  }
}
</script>

<style scoped>

</style>